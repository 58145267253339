// Necessary if using App Router to ensure this file runs on the client
'use client';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    env: process.env.NEXT_PUBLIC_ENV,
    site: 'datadoghq.com',
    service: process.env.NEXT_PUBLIC_SERVICE_NAME,
    version: process.env.VERCEL_GIT_COMMIT_SHA,
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
});

datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: process.env.NEXT_PUBLIC_SERVICE_NAME,
    env: process.env.NEXT_PUBLIC_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.VERCEL_GIT_COMMIT_SHA,
    sessionSampleRate: 10,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    // Specify URLs to propagate trace headers for connection between RUM and backend trace
    allowedTracingUrls: [
        {
            match: process.env.NEXT_PUBLIC_CATEGORY_API_HOSTNAME,
            propagatorTypes: ['datadog', 'tracecontext'],
        },
        {
            match: process.env.NEXT_PUBLIC_CATEGORY_API_LEGACY_HOSTNAME,
            propagatorTypes: ['datadog', 'tracecontext'],
        },
        {
            match: process.env.NEXT_PUBLIC_CLOUD_SEARCH_HOSTNAME,
            propagatorTypes: ['datadog', 'tracecontext'],
        },
        {
            match: process.env.NEXT_PUBLIC_CONTENTFUL_HOSTNAME,
            propagatorTypes: ['datadog', 'tracecontext'],
        },
        {
            match: process.env.NEXT_PUBLIC_CONVERSION_API_HOSTNAME,
            propagatorTypes: ['datadog', 'tracecontext'],
        },
        {
            match: process.env.NEXT_PUBLIC_CONVERSION_FUNNEL_HOSTNAME,
            propagatorTypes: ['datadog', 'tracecontext'],
        },
        {
            match: process.env.NEXT_PUBLIC_PRODUCT_API_HOSTNAME,
            propagatorTypes: ['datadog', 'tracecontext'],
        },
        {
            match: process.env.NEXT_PUBLIC_PRODUCT_UPC_API_HOSTNAME,
            propagatorTypes: ['datadog', 'tracecontext'],
        },
        {
            match: process.env.NEXT_PUBLIC_REVIEWS_DOMAIN,
            propagatorTypes: ['datadog', 'tracecontext'],
        },
        {
            match: process.env.NEXT_PUBLIC_PRODUCT_UPC_API_HOSTNAME,
            propagatorTypes: ['datadog', 'tracecontext'],
        },
        {
            match: process.env.NEXT_PUBLIC_PRODUCT_UPC_API_HOSTNAME,
            propagatorTypes: ['datadog', 'tracecontext'],
        },
    ],
});

export default function DatadogInit() {
    // Render nothing - this component is only included so that the init code
    // above will run client-side
    return null;
}

datadogRum.startSessionReplayRecording();
